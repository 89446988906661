import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Dropdown, Box } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DropdownItem = makeShortcode("DropdownItem");
const DropdownItemAnchor = makeShortcode("DropdownItemAnchor");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "dropdown"
    }}>{`Dropdown`}</h1>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={0} __code={'<Box position=\"relative\" mb=\"xl\">\n  <Dropdown isOpen>\n    <DropdownItem>Option one</DropdownItem>\n    <DropdownItem selected>Selected option</DropdownItem>\n    <DropdownItem>Option three</DropdownItem>\n  </Dropdown>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Dropdown,
      Box,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Box position="relative" mb="xl" mdxType="Box">
    <Dropdown isOpen mdxType="Dropdown">
      <DropdownItem mdxType="DropdownItem">Option one</DropdownItem>
      <DropdownItem selected mdxType="DropdownItem">Selected option</DropdownItem>
      <DropdownItem mdxType="DropdownItem">Option three</DropdownItem>
    </Dropdown>
  </Box>
    </Playground>
    <h2 {...{
      "id": "with-anchors"
    }}>{`With anchors`}</h2>
    <Playground __position={1} __code={'<Box position=\"relative\" mb=\"lg\">\n  <Dropdown isOpen>\n    <DropdownItemAnchor href=\"#\">Link one</DropdownItemAnchor>\n    <DropdownItemAnchor href=\"#\">Link two</DropdownItemAnchor>\n  </Dropdown>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Dropdown,
      Box,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Box position="relative" mb="lg" mdxType="Box">
    <Dropdown isOpen mdxType="Dropdown">
      <DropdownItemAnchor href="#" mdxType="DropdownItemAnchor">Link one</DropdownItemAnchor>
      <DropdownItemAnchor href="#" mdxType="DropdownItemAnchor">Link two</DropdownItemAnchor>
    </Dropdown>
  </Box>
    </Playground>
    <h2 {...{
      "id": "with-caption"
    }}>{`With caption`}</h2>
    <Playground __position={2} __code={'<Box position=\"relative\" mb=\"xl\">\n  <Dropdown isOpen>\n    <Dropdown.Caption>Caption</Dropdown.Caption>\n    <DropdownItem>Foo</DropdownItem>\n    <DropdownItem selected>Bar</DropdownItem>\n  </Dropdown>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Dropdown,
      Box,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Box position="relative" mb="xl" mdxType="Box">
    <Dropdown isOpen mdxType="Dropdown">
      <Dropdown.Caption>Caption</Dropdown.Caption>
      <DropdownItem mdxType="DropdownItem">Foo</DropdownItem>
      <DropdownItem selected mdxType="DropdownItem">Bar</DropdownItem>
    </Dropdown>
  </Box>
    </Playground>
    <h2 {...{
      "id": "with-filter"
    }}>{`With filter`}</h2>
    <Playground __position={3} __code={'<Box position=\"relative\" mb=\"xl\">\n  <Dropdown isOpen>\n    <Dropdown.Input placeholder=\"Type to filter\" />\n    <DropdownItem>Foo</DropdownItem>\n    <DropdownItem selected>Bar</DropdownItem>\n  </Dropdown>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Dropdown,
      Box,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Box position="relative" mb="xl" mdxType="Box">
    <Dropdown isOpen mdxType="Dropdown">
      <Dropdown.Input placeholder="Type to filter" />
      <DropdownItem mdxType="DropdownItem">Foo</DropdownItem>
      <DropdownItem selected mdxType="DropdownItem">Bar</DropdownItem>
    </Dropdown>
  </Box>
    </Playground>
    <Props of={Dropdown} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      